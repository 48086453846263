import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import SectionTitle from '../UI/SectionTitle'

export default function Suggestions() {
  const [t] = useTranslation()

  return (
    <div className="text-defaultText border border-primary bg-green-pale rounded-3xl p-6 h-[150px]">
      <div className="flex items-baseline mb-2">
        <div className="mr-2">
          <SectionTitle text="suggestions" size="xl" marginBottom="mb-0" />
        </div>
        <p className="text-gray-light">Disclaimer: The suggestions are for information purposes and are not a substitution for medical advice.</p>
      </div>
      <div className="pr-4 overflow-auto">
        <ul className="text-justify">
          <li className="flex items-center mt-1"><div className="w-[10px] h-[10px] bg-defaultText rounded-full mr-1" /><p>Proceed with a FIT test. Based on <NavLink className="text-secondary underline">AI suggestion.</NavLink></p></li>
          <li className="flex items-center mt-1"><div className="w-[10px] h-[10px] bg-defaultText rounded-full mr-1" /><p>Proceed with a Colonoscopy. Based on <NavLink className="text-secondary underline">selected rules.</NavLink></p></li>
        </ul>
      </div>
    </div>
  )
}
