import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApproachContainer from '../UI/ApproachContainer'

export default function SemiEmpiricalApproach() {
  const [t] = useTranslation()
  const data = {
    gender: 1,
    age: 24,
    bmi: 35.5,
    activityLevel: 0.5,
    smokingStatus: 0,
    geneticFactors: 0.4,
    alcoholConsumption: 0,
  }

  const initialrisks = Object.entries(data).map(([key, value]) => ({
    name: key,
    value,
  }))

  const [riskFactors, setRiskFactors] = useState(initialrisks)
  const [isEditing, setIsEditing] = useState(false)

  const toggleEditing = useCallback(() => {
    setIsEditing((prevState) => !prevState)
  }, [])

  const renderRiskFactors = () => riskFactors.map((rf) => <tr className="grid grid-cols-4 items-center gap-2 mt-2">
    <td className="col-span-2"><label htmlFor={rf.name}>{t(rf.name)}</label></td>
    <td className="font-bold col-span-1">{rf.value}</td>
    <td className="text-gray-light col-span-1">{isEditing ? <input name={rf.name} id={rf.name} className="w-[40px] border-b border-b-gray-light outline-none" /> : 0.5 + 0.1}</td>
  </tr>)

  return (<>
    <form className="text-defaultText">
      <ApproachContainer title="semiEmpiricalApproach" hasPlusButton isEditing={isEditing} toggleEditing={toggleEditing}>
        <div className="h-[237px] overflow-y-scroll mt-4">
          <table>
            <thead className="text-left">
              <tr className="grid grid-cols-2 items-start">
                <th className="col-span-1 text-xl font-normal">{t('rules')}</th>
                <th className="col-span-1 font-normal"><span className="text-xl">{t('weightOfRule')}</span><br /><span className="text-base">{t('actualOrChange')}</span></th>
              </tr>
            </thead>
            <tbody>
              {renderRiskFactors()}
            </tbody>
          </table>
        </div>
      </ApproachContainer >
    </form></>
  )
}
