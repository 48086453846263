import {
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { LuUserX } from 'react-icons/lu'
import { useNavigation } from 'react-router-dom'
import TableButtonGroup from '../Table/TableButtonGroup'
import WhiteBox from '../UI/WhiteBox'
import ModalWrapper from '../Modals/ModalWrapper'
import ModalActionContent from '../Modals/ModalActionContent'
import Table from '../Table/Table'
import CreatePatientForm from '../Forms/CreateNewPatientForm'

export default function PatientsTable({
  patients,
  title,
  rounded,
}) {
  const [t] = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [patientId, setPatientId] = useState(null)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  const editPatientHandler = (values) => {
    setSelectedPatient(values)
    setShowCreateModal(true)
  }

  const closeModalHandler = () => {
    setShowCreateModal(false)
    setSelectedPatient(null)
  }

  const deletePatientHandler = (id) => {
    setPatientId(id)
    setShowDeleteModal(true)
  }

  // For demo video
  const deletePatient = () => {
    patients.splice(patients.findIndex((p) => p.id === patientId), 1)
  }

  useEffect(() => {
    if (isSubmitting) {
      setShowDeleteModal(false)
    }
  }, [isSubmitting])

  const columns = [
    {
      Header: t('id'),
      accessor: 'id',
    },
    { Header: t('firstName'), accessor: 'name' },
    { Header: t('lastName'), accessor: 'surname' },
    { Header: t('email'), accessor: 'email' },
    { Header: t('phone'), accessor: 'telephone' },
    { Header: t('dateOfBirth'), accessor: 'dateOfBirth' },
    { Header: t('gender'), accessor: 'gender' },
    {
      Header: ' ',
      Cell: ({ row: { original } }) => (
        <TableButtonGroup
          onDelete={(e) => {
            e.stopPropagation()
            deletePatientHandler(original.id)
          }}
          onEdit={(e) => {
            e.stopPropagation()
            editPatientHandler(original)
          }}
        />
      ),
    },
  ]

  const data = useMemo(() => patients, [patients])

  return (
    <>
      <WhiteBox>
        <Table
          type="minimal"
          title={title}
          columns={columns}
          data={data}
          pathKey="doctorManagerPatients"
          rounded={rounded}
          rowsPerPage={10}
          screen="CasesPage"
          hasAddButton
          buttonLabel="addNewPatient"
          clickHandler={editPatientHandler}
          setShowModal={setShowCreateModal}
        // enabledNavigation={true}
        />
        {showCreateModal && selectedPatient && (
          <ModalWrapper onCloseModal={closeModalHandler}
            type="close" title="createNewPatient">
            <div className="p-6">
              <CreatePatientForm onClose={closeModalHandler} patient={selectedPatient} patients={patients} />
            </div>
          </ModalWrapper>
        )}
        {showDeleteModal && (
          <ModalWrapper onCloseModal={() => setShowDeleteModal(false)} type="close">
            <div className="px-6 py-[3rem]">
              <ModalActionContent title="deletePatient"
                description="areYouSureYouWantDeletePatient"
                buttonClassName="bg-red-500 border-red-500 hover:bg-red-500/90 md:max-w-[400px] w-full"
                buttonLabel="yesDeletePatient"
                buttonType="button"
                buttonAction="delete"
                fieldName="patientId"
                id={patientId}
                icon={<LuUserX size={50} />}
                closeModalHandler={() => setShowDeleteModal(false)}
                testOnClick={deletePatient}
              />
            </div>
          </ModalWrapper>
        )}
      </WhiteBox>
    </>
  )
}
