import axiosInstance from '../../utils/axios-instances'

export const getPatients = async () => {
  try {
    const response = await axiosInstance.get('cases/patient/')
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getPatient = async (patientId) => {
  try {
    const response = await axiosInstance.get(`cases/patient/${patientId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const createPatient = async (patient) => {
  try {
    const response = await axiosInstance.post('cases/patient/', patient)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const updatePatient = async (patientId, patient) => {
  try {
    const response = await axiosInstance.patch(`cases/patient/${patientId}/`, patient)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deletePatient = async (patientId) => {
  try {
    await axiosInstance.delete(`cases/patient/${patientId}`)
  } catch (error) {
    throw error.response.data
  }
}
