import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Form, useSubmit } from 'react-router-dom'
import moment from 'moment'
import MainButton from '../UI/MainButton'
import LoginInput from './Input'
import validationSchema from '../../utils/validation-schema'
import SelectInput from './SelectInput'

export default function CreatePatientForm({ onClose, patient, patients }) {
  const [t] = useTranslation()
  const method = patient ? 'patch' : 'post'
  const submit = useSubmit()

  const formik = useFormik({
    initialValues: {
      id: patient && patient.id ? patient.id : '',
      firstName: patient && patient.patient_first_name ? patient.patient_first_name : '',
      lastName: patient && patient.patient_last_name ? patient.patient_last_name : '',
      email: patient && patient.patient_email ? patient.patient_email : '',
      phone: patient && patient.patient_phone ? patient.patient_phone : '',
      dateOfBirth: patient && patient.patient_dob ? patient.patient_dob : '',
      gender: patient && patient.patient_gender ? patient.patient_gender : '',
    },
    validationSchema: method === 'post' ? validationSchema.createPatient : validationSchema.updatePatient,
    onSubmit: async (values, actions) => {
      // submit(values, { method })
      patients.push({
        id: `P01${patients.length + 1}`,
        name: values.firstName,
        surname: values.lastName,
        email: values.email,
        telephone: values.phone,
        dateCreated: moment().format('YYYY-MM-DD'),
        dateOfBirth: values.dateOfBirth,
        gender: values.gender,
      })
      actions.resetForm()
      onClose()
    },
    enableReinitialize: true,
  })

  return (
    <Form onSubmit={formik.handleSubmit} method={method} className="flex flex-col justify-between h-full">
      <div className="grid gap-4 md:grid-cols-2">
        <LoginInput
          type="text"
          name="firstName"
          label={t('firstName')}
          placeholder=""
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={!!(formik.touched.firstName && formik.errors.firstName)}
          errorMessage={formik.errors.firstName}
        />
        <LoginInput
          type="text"
          name="lastName"
          label={t('lastName')}
          placeholder=""
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={!!(formik.touched.lastName && formik.errors.lastName)}
          errorMessage={formik.errors.lastName}
        />
        <LoginInput
          type="email"
          name="email"
          label={t('email')}
          placeholder=""
          value={formik.values.email}
          onChange={formik.handleChange}
          error={!!(formik.touched.email && formik.errors.email)}
          errorMessage={formik.errors.email}
        />
        <LoginInput
          type="text"
          name="phone"
          label={t('phone')}
          placeholder=""
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={!!(formik.touched.phone && formik.errors.phone)}
          errorMessage={formik.errors.phone}
        />
        <LoginInput
          type="date"
          name="dateOfBirth"
          label={t('dateOfBirth')}
          placeholder=""
          value={formik.values.dateOfBirth}
          onChange={formik.handleChange}
          error={!!(formik.touched.dateOfBirth && formik.errors.dateOfBirth)}
          errorMessage={formik.errors.dateOfBirth}
        />
        <SelectInput
          name="gender"
          label={t('gender')}
          placeholder=""
          value={formik.values.gender}
          onChange={formik.handleChange}
          error={!!(formik.touched.gender && formik.errors.gender)}
          errorMessage={formik.errors.gender}>
          <option value="">{t('chooseOneOption')}</option>
          <option value="Male">{t('male')}</option>
          <option value="Female">{t('female')}</option>
          <option value="Other">{t('otherGender')}</option>
        </SelectInput>
      </div>
      <div className="flex flex-col justify-end w-full gap-4 mt-9 md:flex-row">
        <MainButton
          text={t('cancel')}
          type="button"
          onClick={onClose}
          className="rounded-xl"
          isCancel
        />
        <MainButton text={method === 'post' ? t('create') : t('edit')} type="submit" className="w-full md:max-w-[270px] rounded-xl" />
      </div>
    </Form>
  )
}
